export const navLinks = [
  {
    href: "https://www.mintscan.io/sentinel",
    title: "Blockchain",
  },
  {
    href: "https://docs.sentinel.co/node-setup",
    title: "Run a Node",
  },
  {
    href: "https://github.com/sentinel-official",
    title: "Github",
  },
];

export const navDropdownItems = {
  0: "All Nodes",
  1: "WireGuard",
  2: "V2Ray",
  3: "HNS Resolvers",
};
