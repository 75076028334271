import PropTypes from "prop-types";
import React from "react";
import { formatBytes } from "../../helpers/formatBytes";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const parseDates = (timestamp) => {
  const dt = new Date(timestamp);
  return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`;
};

const TootipData = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <span>{parseDates(payload[0].payload.name)}</span>
        <span>{formatBytes(payload[0].payload.value)}</span>
      </div>
    );
  }
  return null;
};

TootipData.propTypes = {
  active: PropTypes.any,
  payload: PropTypes.any,
};

const Graph = () => {
  const { graph } = useSelector((state) => state.bandwidth);

  return (
    <ResponsiveContainer aspect={2.4}>
      <LineChart data={graph}>
        <XAxis dataKey="name" hide={true} />
        <YAxis hide={true} />
        <Tooltip content={<TootipData />} />
        <Line type="monotone" dataKey="value" stroke="#00B6FA" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default React.memo(Graph);
