import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import InfoIcon from "../../assets/icons/InfoIcon";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { Tooltip } from "antd";
import styles from "./card.module.scss";

const AggregatedCard = ({
  changedValue,
  description,
  isChangeNegative,
  isLinkExternel,
  link,
  linkState,
  powerredBy,
  title,
  value,
  valuePrefix,
  valueSuffix,
}) => (
  <>
    <Link
      to={link}
      target={isLinkExternel ? "_blank" : ""}
      rel={isLinkExternel ? "noopener noreferrer" : ""}
      state={linkState}
    >
      <div className={styles.card}>
        <div className={styles.aggregated}>
          <header>
            {title && <span className={styles.cardTitle}>{title}</span>}
            {description && (
              <Tooltip placement="top" title={description}>
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "help",
                    outline: "none",
                  }}
                >
                  <InfoIcon />
                </button>
              </Tooltip>
            )}
          </header>
          <main>
            {valuePrefix && <span>{valuePrefix}</span>}
            <span>{value}</span>
            {valueSuffix && <span>{valueSuffix}</span>}
          </main>
          <footer>
            <section className={styles.footerLeft}>
              <span>
                {changedValue && (
                  <div
                    className={`${
                      isChangeNegative
                        ? styles.changedNegative
                        : styles.changedPositive
                    } `}
                  >
                    <span>{!isChangeNegative && "+"}</span>
                    <span>{changedValue}</span>
                    <span>%</span>
                  </div>
                )}
              </span>
            </section>
            <section className={styles.footerRight}>
              {link && <ArrowRightIcon style={{ fontSize: "8px" }} />}
              {powerredBy && (
                <span className={styles.powerredBy}>{powerredBy}</span>
              )}
            </section>
          </footer>
        </div>
      </div>
    </Link>
  </>
);

AggregatedCard.propTypes = {
  changedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string.isRequired,
  isChangeNegative: PropTypes.bool,
  isLinkExternel: PropTypes.bool,
  link: PropTypes.string,
  linkState: PropTypes.object,
  powerredBy: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  valuePrefix: PropTypes.string,
  valueSuffix: PropTypes.string,
};
export default AggregatedCard;
