import DetailsBox from "./DetailsBox";
import NodeItemsListItem from "../Components/ListView/NodeItemsListItem";
import React from "react";
import { navDropdownItems } from "../constants/links.constants";
import { setCurrentParams } from "../reducers/navigation.reducer";
import styles from "../styles/nodes-list.module.scss";
import { resetMapViewport, setMapViewport } from "../reducers/map.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

const NodesList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const searchParamsLatitude = searchParams
    ? searchParams.get("latitude")
    : null;
  const searchParamsLongitude = searchParams
    ? searchParams.get("longitude")
    : null;

  const filteredData = useSelector((state) => state.nodes.filteredData);
  const hnsNodes = useSelector((state) => state.nodes.hnsNodes);
  const filterBy = useSelector((state) => state.nodes.filterBy);
  const navigation = useSelector((state) => state.navigation);

  const [data, setData] = React.useState([]);
  const [subtitle, setSubTitle] = React.useState("");

  React.useEffect(() => {
    const handshake = location.state?.handshake;
    if (handshake && handshake?.enable) {
      setData(hnsNodes);
      setSubTitle("HNS Resolvers");
      return;
    }

    let nodes = filteredData;
    if (filterBy === "3") {
      nodes = hnsNodes;
    }

    if (params && params.country && params.city) {
      const data = nodes.filter(
        (node) =>
          node.location.city === params.city &&
          node.location.country === params.country
      );
      const subtitle = `${params.city}, ${params.country}`;

      setData(data);
      setSubTitle(subtitle);
      return;
    }

    if (searchParamsLatitude && searchParamsLongitude) {
      dispatch(
        setMapViewport({
          latitude: searchParamsLatitude,
          longitude: searchParamsLongitude,
          zoom: [14],
        })
      );
      const data = nodes.filter(
        (node) =>
          node.location.latitude.toString() === searchParamsLatitude &&
          node.location.longitude.toString() === searchParamsLongitude
      );
      const subtitle = `Lat: ${searchParamsLatitude}, Long: ${searchParamsLongitude}`;

      setData(data);
      setSubTitle(subtitle);
      return;
    }

    setData(nodes);
    setSubTitle(navDropdownItems[Number.parseInt(filterBy)]);
    return;
  }, [
    filteredData.length,
    filterBy,
    navigation.city,
    navigation.country,
    navigation.searchParamsLatitude,
    navigation.searchParamsLongitude,
  ]);

  React.useEffect(() => {
    dispatch(
      setCurrentParams({
        city: params.city,
        country: params.country,
        searchParamsLatitude,
        searchParamsLongitude,
      })
    );
    return () =>
      dispatch(
        setCurrentParams({
          city: "",
          country: "",
          searchParamsLatitude: "",
          searchParamsLongitude: "",
        })
      );
  }, [
    params.city,
    params.country,
    searchParamsLatitude,
    searchParamsLongitude,
  ]);

  const handleResetMapViewport = () => {
    dispatch(resetMapViewport());
  };

  const nodesList = data.map((node, index) => (
    <NodeItemsListItem
      index={index}
      key={node.addr}
      node={node}
      city={params.city}
      country={params.country}
      isHnsNode={location.state?.handshake?.enable}
    />
  ));
  return (
    <DetailsBox subtitle={subtitle} onBackClick={handleResetMapViewport}>
      <section className={styles.nodesList}>{nodesList}</section>
    </DetailsBox>
  );
};

export default React.memo(NodesList);
