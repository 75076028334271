export const mapConfig = {
  styles: {
    basic: "mapbox://styles/mapbox/basic-v9",
    custom: "mapbox://styles/asif7774/cl0ekorls001214o2pk8vu229",
    dark: "mapbox://styles/mapbox/dark-v9",
    light: "mapbox://styles/mapbox/light-v9",
    outdoor: "mapbox://styles/mapbox/outdoors-v10",
  },
  token:
    "pk.eyJ1IjoiYXNpZjc3NzQiLCJhIjoiY2t6MDBsemx3MGpmMjJ2bzE2aG54czI0byJ9.nae0UyMtDHUldZgbkLFnYQ",
};
