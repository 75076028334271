/* eslint-disable sort-keys */
import { fetchData } from "../api/fetchData";

export const getCurrentActiveNodeCount = async () => {
  const response = await fetchData(
    "https://api.explorer.sentinel.co/v2/statistics",
    { method: "CurrentNodeCount", status: "active" }
  );
  return response.result;
};

export const fetchHistoricalBandwidthData = async () => {
  const response = await fetchData(
    "https://api.explorer.sentinel.co/v2/statistics",
    {
      limit: 30,
      duration: "day",
      method: "HistoricalSessionBytes",
      sort: "-timestamp"
    }
  );
  return response.result;
};

export const fetchMarketPrice = async () => {
  const response = await fetchData(
    "https://api.coingecko.com/api/v3/coins/sentinel",
    {
      community_data: false,
      developer_data: false,
      localization: false,
      sparkline: false,
      tickers: false,
    }
  );
  return response.market_data;
};

export const fetchActiveNodes = async (total) => {
  const promises = [];
  for (let skip = 0; skip <= total; skip += 100) {
    promises.push(
      fetchData(
        "https://api.explorer.sentinel.co/v2/nodes",
        {
          limit: 100,
          skip,
          status: "active",
          sort: "-register_height",
        }
      )
    );
  }
  const responses = [];

  for (const promise of promises) {
    try {
      const resp = await promise;
      responses.push(resp.result);
    } catch (error) {
      responses.push({ success: false, result: [] });
    }
  }
  return responses.flat(1);
};

//https://api.explorer.sentinel.co/api/v1/nodes?limit=100&skip=0&status=active
