import axios from "axios";

const Axios = axios.create({
  baseURL: "https://api.explorer.sentinel.co/v2/statistics",
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchData = async (url = "", params = {}) => {
  try {
    const response = await Axios.get(url, {
      params: params
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
