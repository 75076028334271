import React from "react";

const ArrowRightIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4 3.35L8.20001 0.15C8.00001 -0.05 7.60001 -0.05 7.40001 0.15C7.20001 0.35 7.20001 0.749995 7.40001 0.949995L9.70001 3.25H0.600006H0.5C0.2 3.25 0 3.55 0 3.85C0 4.15 0.300006 4.35 0.600006 4.35H9.70001L7.40001 6.65C7.30001 6.75 7.20001 6.85 7.20001 7.05C7.20001 7.35 7.4 7.65 7.8 7.65C7.9 7.65 8.10001 7.55 8.20001 7.45L11.4 4.25C11.7 3.85 11.7 3.55 11.4 3.35Z"
      transform="translate(0)"
      fill="#686868"
    ></path>
  </svg>
);

export default ArrowRightIcon;
