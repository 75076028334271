import React from "react";
import { navLinks } from "../../constants/links.constants";
import styles from "./styles.module.scss";
const NavLinks = () => (
  <section className={styles.navLinks}>
    {navLinks.map((link) => (
      <a
        key={link.title}
        className={styles.navLink}
        href={link.href}
        target="_blank"
        rel="noreferrer"
      >
        {link.title}
      </a>
    ))}
  </section>
);

export default React.memo(NavLinks);
