import { CaretDownOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";
import { navDropdownItems } from "../../constants/links.constants";
import { setFilterBy } from "../../reducers/data.reducer";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { Dropdown, Switch } from "antd";

const DropdownItem = ({ handleDropdownSelected, index, isEnabled, title }) => (
  <section
    className={styles.dropdownItem}
    onClick={() => handleDropdownSelected(index)}
  >
    <p>{title}</p>
    <Switch
      checked={isEnabled}
      size="small"
      onClick={() => handleDropdownSelected(index)}
    />
  </section>
);

DropdownItem.propTypes = {
  handleDropdownSelected: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

const NodeViewDropDown = () => {
  const [enabledItems, setEnabledItems] = React.useState("0");
  const dispatch = useDispatch();
  const handleDropdownSelected = (item) => {
    setEnabledItems(item);
    dispatch(setFilterBy(item));
  };

  const items = Object.values(navDropdownItems).map((item, index) => ({
    key: index,
    label: (
      <DropdownItem
        title={item}
        index={`${index}`}
        isEnabled={enabledItems.includes(`${index}`)}
        handleDropdownSelected={handleDropdownSelected}
      />
    ),
  }));

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomLeft"
      className={styles.dropdownOverlay}
      overlayClassName={styles.dropdownOverlay}
    >
      <p className={styles.dropdownTitle}>
        Node View <CaretDownOutlined />
      </p>
    </Dropdown>
  );
};

export default React.memo(NodeViewDropDown);
