import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import countryCode from "../../constants/countryCodes";
import { formatBytes } from "../../helpers/formatBytes";
import styles from "./styles.module.scss";

const NodeItemsListItem = ({ city, country, node, isHnsNode, index }) => {
  const item = {
    city: node.location.city,
    country: node.location.country,
    name: node.moniker,
    speed: `${formatBytes(
      Number.parseInt(node.internet_speed.upload) +
        Number.parseInt(node.internet_speed.download)
    )}ps`,
  };
  return (
    <Link
      to={`/nodes/${node.addr}`}
      state={{ isHnsNode, node }}
      className={`${styles.listItem} ${
        index % 2 === 0 ? styles.listItemEven : ""
      }`}
    >
      <span>
        <ReactCountryFlag
          countryCode={countryCode[item.country]}
          svg
          style={{
            height: "1.5em",
            width: "1.5em",
          }}
          title={item.country}
        />
      </span>
      <span className={styles.listItemName}>
        {`${item.name} ${city ? "" : ", " + item.city} ${
          country ? "" : ", " + item.country
        }`}
      </span>
      <span>{item.speed}</span>
    </Link>
  );
};

NodeItemsListItem.propTypes = {
  city: PropTypes.any,
  country: PropTypes.any,
  index: PropTypes.number.isRequired,
  isHnsNode: PropTypes.bool,
  node: PropTypes.any.isRequired,
};
export default NodeItemsListItem;
