import Graph from "../Graph";
import InfoIcon from "../../assets/icons/InfoIcon";
import PropTypes from "prop-types";
import React from "react";
import { Tooltip } from "antd";
import { formatBytes } from "../../helpers/formatBytes";
import styles from "./card.module.scss";
import { useSelector } from "react-redux";

const BandwidthCard = () => {
  const { total } = useSelector((state) => state.bandwidth);
  return (
    <div className={styles.bandwidthCard}>
      <section className={styles.bandwidthCardLeft}>
        <header>
          <span className={styles.cardTitle}>Bandwidth</span>
          <Tooltip
            placement="top"
            title={"Total bandwidth shared and used across the network."}
          >
            <button
              className={"ghostButton"}
              style={{ background: "none", cursor: "help" }}
            >
              <InfoIcon />
            </button>
          </Tooltip>
        </header>
        <main>
          <span>{formatBytes(total)}</span>
        </main>
      </section>
      <section className={styles.bandwidthCardRight}>
        <Graph />
        <span className={styles.bottomLabel}>30 Day Trend</span>
      </section>
    </div>
  );
};

BandwidthCard.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

export default BandwidthCard;
