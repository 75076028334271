import DetailsBox from "./DetailsBox";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import countryCode from "../constants/countryCodes";
import styles from "../styles/nodes-list.module.scss";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const CountriesList = () => {
  const data = useSelector((state) => state.nodes.countries);
  const params = useParams();
  const [countries, setCountries] = React.useState([]);

  React.useEffect(() => {
    if (params && params.continent) {
      setCountries(
        data.filter((country) => country.continent === params.continent)
      );
      return;
    }
    setCountries(data);
  }, [data.length]);
  return (
    <DetailsBox subtitle={params.continent || "COUNTRIES"}>
      <section className={styles.nodesList}>
        {countries &&
          countries.length > 0 &&
          countries.map(({ country, count }, index) => (
            <Link
              key={country}
              to={`/countries/${country}/cities`}
              className={`${styles.listItem} ${
                index % 2 === 0 ? styles.listItemEven : ""
              }`}
            >
              <span>
                <ReactCountryFlag
                  countryCode={countryCode[country]}
                  svg
                  style={{
                    height: "1.5em",
                    width: "1.5em",
                  }}
                  title={country}
                />
              </span>
              <span className={styles.listItemName}>{country}</span>
              <span className={styles.listItemName}>
                {count.toLocaleString()}
              </span>
            </Link>
          ))}
      </section>
    </DetailsBox>
  );
};

export default CountriesList;
