import { createSlice } from "@reduxjs/toolkit";
import { getCurrentActiveNodes } from "../actions/data.actions";
import { getUniqueLocations } from "../helpers/getCountsOfData";
const intialViewPort = {
  latitude: 20.5937,
  longitude: 78.9629,
  zoom: [2.5],
};
const mapSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getCurrentActiveNodes.fulfilled, (state, { payload }) => ({
      ...state,
      locations: getUniqueLocations(payload.data),
    }));
  },
  initialState: {
    locations: [],
    viewport: intialViewPort,
  },
  name: "map",
  reducers: {
    resetMapViewport: (state, { payload }) => ({
      ...state,
      viewport: {
        ...state.viewport,
        latitude: payload?.viewport?.latitude
          ? payload.viewport.latitude
          : state.viewport.latitude,
        longitude: payload?.viewport?.longitude
          ? payload.viewport.longitude
          : state.viewport.longitude,
        zoom: payload?.viewport?.zoom ? payload.viewport.zoom : [2.5],
      },
    }),
    setMapViewport: (state, { payload }) => ({
      ...state,
      viewport: {
        ...state.viewport,
        ...payload,
      },
    }),
  },
});
export const { resetMapViewport, setMapViewport } = mapSlice.actions;
export default mapSlice.reducer;
