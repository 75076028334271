import DetailsBox from "./DetailsBox";
import { Link } from "react-router-dom";
import React from "react";
import { getContinents } from "../helpers/getCountsOfData";
import styles from "../styles/nodes-list.module.scss";
import { useSelector } from "react-redux";

const ContinentsList = () => {
  const filteredData = useSelector((state) => state.nodes.filteredData);
  const [continents, setContinents] = React.useState([]);

  React.useEffect(() => {
    const continents = getContinents(filteredData);
    setContinents(continents);
  }, [filteredData.data]);
  return (
    <DetailsBox subtitle={"CONTINENTS"}>
      <section className={styles.nodesList}>
        {continents &&
          continents.length > 0 &&
          continents.map(({ name, count }, index) => (
            <Link
              key={name}
              to={`/continents/${name}/countries`}
              className={`${styles.listItem} ${
                index % 2 === 0 ? styles.listItemEven : ""
              }`}
            >
              <span></span>
              <span className={styles.listItemName}>{name}</span>
              <span className={styles.listItemName}>
                {count.toLocaleString()}
              </span>
            </Link>
          ))}
      </section>
    </DetailsBox>
  );
};

export default ContinentsList;
