import { CONTINENT_MAP } from "../constants/continentMapper";

export const getUniqueLocations = (array) => {
  const locations = [];
  array.forEach((element) => {
    const index = locations.findIndex(
      (obj) =>
        element.location?.latitude &&
        obj.latitude === element.location?.latitude &&
        element.location?.longitude &&
        obj.longitude === element.location?.longitude
    );

    if (index !== -1) {
      locations[index].count++;
    } else {
      locations.push({
        city: element.location?.city,
        count: 1,
        country: element.location?.country,
        latitude: element.location?.latitude,
        longitude: element.location?.longitude,
      });
    }
  });

  return locations.sort((a, b) => a.city?.localeCompare(b?.city));
};

export const getUniqueCities = (array) => {
  const cities = [];
  array.forEach((element) => {
    const index = cities.findIndex(
      (obj) =>
        element.location?.city &&
        obj.city === element.location?.city &&
        element.location?.country &&
        obj.country === element.location?.country
    );

    if (index !== -1) {
      cities[index].count++;
    } else {
      cities.push({
        city: element.location?.city,
        count: 1,
        country: element.location?.country,
        latitude: element.location?.latitude,
        longitude: element.location?.longitude,
      });
    }
  });

  return cities.sort((a, b) => a.city?.localeCompare(b?.city));
};

export const getContinentName = (contryName) => {
  for (var i = 0; i < CONTINENT_MAP.length; i++) {
    for (var j = 0; j < CONTINENT_MAP[i].countries.length; j++) {
      if (CONTINENT_MAP[i].countries[j] === contryName)
        return CONTINENT_MAP[i].continent;
    }
  }
  return "Unknown";
};

export const getUniqueCountries = (array) => {
  const countries = [];
  array.forEach((element) => {
    const index = countries.findIndex(
      (obj) =>
        element.location?.country &&
        obj.country === element.location?.country &&
        element.location?.continent &&
        obj.continent === element.location?.continent
    );

    if (index !== -1) {
      countries[index].count++;
    } else {
      countries.push({
        continent: element.location?.continent,
        count: 1,
        country: element.location?.country,
      });
    }
  });

  return countries.sort((a, b) => a.country?.localeCompare(b?.country));
};

export const getContinents = (array) => {
  const continents = [];
  array.forEach((element) => {
    const index = continents.findIndex(
      (continent) => continent.name === element.location?.continent
    );

    if (index !== -1) {
      continents[index].count++;
    } else {
      continents.push({
        count: 1,
        name: element.location?.continent,
      });
    }
  });

  return continents.sort((a, b) => a.name?.localeCompare(b?.name));
};
