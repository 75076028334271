import React from "react";

const InfoIcon = () => (
  <svg
    width="0.9em"
    height="0.9em"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.88,13.454a6.872,6.872,0,1,1,2,.3A6.873,6.873,0,0,1,4.88,13.454ZM3.058,2.225A6.013,6.013,0,1,0,6.874.859,6.015,6.015,0,0,0,3.058,2.225Zm3.5,8.027A.779.779,0,0,1,6.1,9.458l.3-3.013a.474.474,0,0,1,.794-.31.478.478,0,0,1,.151.31l.3,3.013a.782.782,0,0,1-.033.322.765.765,0,0,1-.162.281.774.774,0,0,1-.262.191.777.777,0,0,1-.634,0Zm.151-5.113a.86.86,0,1,1,.168.017A.861.861,0,0,1,6.705,5.139Z"
      transform="translate(0)"
      fill="#686868"
    ></path>
  </svg>
);

export default InfoIcon;
