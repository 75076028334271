import { AutoComplete } from "antd";
import React from "react";
import SearchIcon from "../../assets/icons/SearchIcon";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Search = () => {
  const filteredData = useSelector((state) => state.nodes.filteredData);
  const navigate = useNavigate();
  const handlesearch = (addr) => {
    navigate(`/nodes/${addr}`);
  };
  return (
    <div className={styles.search}>
      <SearchIcon />
      <AutoComplete
        aria-label=""
        bordered={false}
        style={{ color: "black", fontWeight: "300", width: "20vw" }}
        options={filteredData.map((data) => ({ value: data.addr }))}
        placeholder={"Search for nodes.."}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        allowClear={true}
        onSelect={handlesearch}
        className={styles.searchInput}
      />
    </div>
  );
};

export default React.memo(Search);
