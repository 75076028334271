/* eslint-disable react/prop-types */
import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";
import history from "../helpers/history";
import { resetMapViewport } from "../reducers/map.reducer";
import { setCurrentParams } from "../reducers/navigation.reducer";
import styles from "../styles/details-box.module.scss";
import { useDispatch } from "react-redux";
import {
  ArrowLeftOutlined,
  HomeFilled,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

const Header = ({ hide, setHide }) => {
  const location = useLocation();
  const isNotHome = location.pathname !== "/";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <section
      className={`${styles.cardHeaderMain} ${
        isNotHome ? styles.showingMainBack : ""
      }`}
    >
      <section>
        {isNotHome && (
          <Button
            icon={<HomeFilled style={{ fontSize: "16px" }} />}
            ghost={true}
            className={"ghostButton"}
            onClick={() => {
              dispatch(resetMapViewport());
              dispatch(
                setCurrentParams({
                  city: "",
                  country: "",
                })
              );
              navigate("/");
            }}
          />
        )}
        <span>{"SENTINEL EXPLORER"}</span>
      </section>
      <Button
        icon={hide ? <PlusOutlined /> : <MinusOutlined />}
        ghost={true}
        className={"ghostButton"}
        onClick={() => {
          setHide(!hide);
        }}
      />
    </section>
  );
};
const MainHeader = React.memo(Header);

const DetailsBox = ({ children, onBackClick, subtitle }) => {
  const [hide, setHide] = React.useState(false);
  return (
    <div className={styles.card}>
      <header className={styles.cardHeader}>
        <MainHeader setHide={setHide} hide={hide} />
        {!hide && (
          <section className={styles.cardMainSubTitle}>
            {subtitle && (
              <section>
                <span>
                  <Button
                    icon={<ArrowLeftOutlined style={{ fontSize: "16px" }} />}
                    ghost={true}
                    className={"ghostButton"}
                    onClick={() => {
                      onBackClick && onBackClick();
                      history.back();
                    }}
                  />
                </span>
                <span>{subtitle}</span>
              </section>
            )}
          </section>
        )}
      </header>
      {!hide && <main className={styles.cardMain}>{children}</main>}
    </div>
  );
};
DetailsBox.propTypes = {
  children: PropTypes.any,
  onBackClick: PropTypes.func,
  subtitle: PropTypes.string,
};
export default DetailsBox;
