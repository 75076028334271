import { createSlice } from "@reduxjs/toolkit";
import { filterByType } from "../helpers/filterData";
import {
  getCurrentActiveNodes,
  getHistoricalBandwidthData,
  getMarketPrice,
} from "../actions/data.actions";
import {
  getUniqueCities,
  getUniqueCountries,
} from "../helpers/getCountsOfData";

const bandwidthSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getHistoricalBandwidthData.pending, (state) => state)
      .addCase(getHistoricalBandwidthData.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(getHistoricalBandwidthData.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        loading: false,
      }));
  },
  initialState: {
    graph: [],
    loading: true,
    total: 0,
  },
  name: "bandwidth",
});
export const bandwidthReducer = bandwidthSlice.reducer;

export const marketPriceSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getMarketPrice.pending, (state) => state)
      .addCase(getMarketPrice.rejected, (state) => state)
      .addCase(getMarketPrice.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        loading: false,
      }));
  },
  initialState: {
    changedValue: 0,
    isChangeNegative: false,
    value: 0.0,
  },
  name: "marketPrice",
});

export const marketPriceReducer = marketPriceSlice.reducer;

const dataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentActiveNodes.pending, (state) => ({ ...state }))
      .addCase(getCurrentActiveNodes.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(getCurrentActiveNodes.fulfilled, (state, { payload }) => {
        const hnsNodes = payload.data.filter((node) => {
          if (node?.handshake_dns?.enable) return node;
        });
        return {
          ...state,
          cities: getUniqueCities(payload.data),
          countries: getUniqueCountries(payload.data),
          filteredData: payload.data,
          hnsNodes,
          loading: false,
          nodes: payload.data,
          total: payload.total,
        };
      });
  },
  initialState: {
    cities: [],
    countries: [],
    filterBy: "0",
    filteredData: [],
    hnsNodes: [],
    loading: true,
    nodes: [],
    total: 0,
  },
  name: "nodes",
  reducers: {
    setFilterBy: (state, { payload }) => {
      const filteredData = filterByType(state.nodes, payload);
      const hnsNodes = filteredData.filter((node) => {
        if (node?.handshake_dns?.enable) return node;
      });
      return {
        ...state,
        cities: getUniqueCities(filteredData),
        countries: getUniqueCountries(filteredData),
        filterBy: payload,
        filteredData,
        hnsNodes,
      };
    },
  },
});

export const { setFilterBy } = dataSlice.actions;
export default dataSlice.reducer;
