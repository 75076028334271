import NavLinks from "./NavLinks";
import NodeViewDropDown from "./NodeViewDropDown";
import React from "react";
import Search from "./Search";
import SentinelLogo from "../../assets/logos/SentinelLogo.svg";
import styles from "./styles.module.scss";

const Header = () => (
  <div className={styles.header}>
    <section className={styles.headerLeft}>
      <img src={SentinelLogo} alt="" />
    </section>
    <section className={styles.headerRight}>
      <NavLinks />
      <NodeViewDropDown />
      <Search />
    </section>
  </div>
);

export default React.memo(Header);
