import React from "react";

const SearchIcon = () => (
  <svg
    width="0.9em"
    height="0.9em"
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.242 10.3429C13.2103 9.02184 13.6441 7.38386 13.4567 5.75664C13.2693 4.12942 12.4744 2.63296 11.2311 1.56658C9.98784 0.500193 8.38778 -0.057476 6.751 0.00511819C5.11422 0.0677124 3.56142 0.745954 2.4032 1.90418C1.24498 3.0624 0.566736 4.6152 0.504142 6.25198C0.441547 7.88875 0.999216 9.48882 2.0656 10.7321C3.13198 11.9754 4.62845 12.7703 6.25566 12.9577C7.88288 13.1451 9.52086 12.7113 10.842 11.7429C10.8728 11.7834 10.9062 11.8218 10.942 11.8579L14.792 15.7079C14.9795 15.8956 15.2338 16.0011 15.4991 16.0012C15.7644 16.0012 16.0188 15.896 16.2065 15.7084C16.3941 15.5209 16.4996 15.2666 16.4997 15.0013C16.4998 14.736 16.3945 14.4816 16.207 14.2939L12.357 10.4439C12.3212 10.4077 12.2828 10.3743 12.242 10.3439V10.3429ZM12.5 6.49895C12.5 7.7714 12.0588 9.00451 11.2516 9.98815C10.4444 10.9718 9.32112 11.6451 8.07313 11.8934C6.82513 12.1417 5.52965 11.9496 4.40743 11.3498C3.2852 10.75 2.40567 9.7796 1.91869 8.60402C1.43171 7.42844 1.36741 6.12037 1.73676 4.90269C2.1061 3.68502 2.88623 2.63308 3.94422 1.92611C5.00221 1.21915 6.2726 0.9009 7.53894 1.02559C8.80527 1.15029 9.98919 1.7102 10.889 2.60995C11.9204 3.64136 12.4999 5.04027 12.5 6.49895V6.49895Z"
      fill="#767676"
    ></path>
  </svg>
);

export default SearchIcon;
