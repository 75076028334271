import AggregatedCard from "../Components/Card/AggregatedCard";
import BandwidthCard from "../Components/Card/BandwidthCard";
import DetailsBox from "./DetailsBox";
import React from "react";
import styles from "../styles/explorer-card.module.scss";
import { useSelector } from "react-redux";

const allNodesTitles = [
  {
    description: "Total Number of active dVPN Nodes on the Network.",
    title: "All Nodes",
  },
  {
    description:
      "Number of active Wireguard nodes hosted on the Sentinel Network.",
    title: "WireGuard Nodes",
  },
  {
    description: "Number of active V2Ray nodes on the Sentinel Network.",
    title: "V2Ray Nodes",
  },
];
const ExplorerCard = () => {
  const { filteredData, hnsNodes, filterBy, cities, countries } = useSelector(
    (state) => state.nodes
  );
  const marketPrice = useSelector((state) => state.marketPrice);
  const cardsData = [
    {
      description: "dVPN Price Data Provided by CoinGecko.",
      isLinkExternel: true,
      link: "https://www.coingecko.com/en/coins/sentinel",
      powerredBy: "Powered by CoinGecko",
      title: "Market Place",
      valuePrefix: "$",
      ...marketPrice,
    },
    {
      description:
        "Number of Handshake Network Resolvers on the Sentinel Network",
      link: "/nodes",
      linkState: {
        handshake: {
          enable: true,
        },
      },
      title: "HNS Resolvers",
      value: hnsNodes.length.toLocaleString(),
    },
    ...(["0", "1", "2"].includes(filterBy)
      ? [
          {
            link: "/continents",
            value: filteredData.length.toLocaleString(),
            ...allNodesTitles[Number.parseInt(filterBy)],
          },
        ]
      : []),
    {
      description: "Number of cities dVPN Nodes are currently hosted in.",
      link: "/cities",
      title: "dVPN Node Cities",
      value: cities.length,
      valueSuffix: "Cities",
    },
    {
      description: "Number of Countries dVPN Nodes are Hosted in.",
      link: "/countries",
      title: "dVPN Node Countries",
      value: countries.length,
      valueSuffix: "Countries",
    },
  ];

  return (
    <DetailsBox>
      <BandwidthCard />
      <section className={styles.aggregatedCards}>
        {cardsData.map((card) => (
          <AggregatedCard key={`${card.title}`} {...card} />
        ))}
      </section>
    </DetailsBox>
  );
};

export default ExplorerCard;
