import DetailsBox from "./DetailsBox";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import countryCode from "../constants/countryCodes";
import styles from "../styles/nodes-list.module.scss";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const CitiesList = () => {
  const data = useSelector((state) => state.nodes.cities);
  const [cities, setCities] = React.useState([]);
  const params = useParams();

  React.useEffect(() => {
    if (params && params.country) {
      setCities(data.filter((city) => city.country === params.country));
      return;
    }
    setCities(data);
  }, [data.length]);

  return (
    <DetailsBox subtitle={params.country || "CITIES"}>
      <section className={styles.nodesList}>
        {cities &&
          cities.length > 0 &&
          cities.map(({ city, country, count }, index) => (
            <Link
              key={`${city}, ${country}`}
              to={`/countries/${country}/cities/${city}/nodes`}
              className={`${styles.listItem} ${
                index % 2 === 0 ? styles.listItemEven : ""
              }`}
            >
              <span>
                <ReactCountryFlag
                  countryCode={countryCode[country]}
                  svg
                  style={{
                    height: "1.8em",
                    width: "1.8em",
                  }}
                  title={country}
                />
              </span>
              <span>{`${city} ${params.country ? "" : ", " + country}`}</span>
              <span>{count.toLocaleString()}</span>
            </Link>
          ))}
      </section>
    </DetailsBox>
  );
};

export default CitiesList;
