import DetailsBox from "./DetailsBox";
import MapZoomOutIcon from "../assets/icons/MapZoomOutIcon";
import PropTypes from "prop-types";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import countryCode from "../constants/countryCodes";
import { formatBytes } from "../helpers/formatBytes";
import styles from "../styles/node-details.module.scss";
import { AimOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { resetMapViewport, setMapViewport } from "../reducers/map.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

const types = {
  0: "Unknown",
  1: "WireGuard",
  2: "V2Ray",
};

const NodeDetailsCard = ({ clasName, title, value, isCopy }) => (
  <div className={`${styles.nodeDetailsCard} ${clasName}`}>
    <section className={styles.nodeDetailsCardTitle}>
      <span>{title}</span>
      {isCopy && (
        <Tooltip title={"Copy"}>
          <CopyOutlined
            className={styles.copyIcon}
            onClick={() => {
              navigator.clipboard.writeText(value);
            }}
          />
        </Tooltip>
      )}
    </section>
    <span className={styles.nodeDetailsCardValue}>{value}</span>
  </div>
);
NodeDetailsCard.propTypes = {
  clasName: PropTypes.string,
  isCopy: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
};
const NodeDetails = () => {
  const params = useParams();
  const location = useLocation();
  const [node, setNode] = React.useState({});
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.nodes.nodes);
  const mapState = useSelector((state) => state.map.viewport);

  React.useEffect(() => {
    let currentNode = nodes.filter((n) => n.addr === params.addr);

    if (currentNode && currentNode[0] && currentNode[0].addr) {
      dispatch(
        setMapViewport({
          latitude: currentNode[0].location.latitude,
          longitude: currentNode[0].location.longitude,
          zoom: [14],
        })
      );
      setNode(currentNode[0]);
    }
  }, [nodes.length, params.addr, location.state?.node]);

  const handleOnBackClick = () => {
    if (location.state?.isHnsNode) {
      if (mapState.zoom > 2.5) {
        dispatch(resetMapViewport());
      } else {
        dispatch(
          resetMapViewport({
            viewport: {
              zoom: [14],
            },
          })
        );
      }
    }
  };
  const handleResetMapViewport = () => {
    if (mapState.zoom > 2.5) {
      dispatch(resetMapViewport());
    } else {
      dispatch(
        resetMapViewport({
          viewport: {
            zoom: [14],
          },
        })
      );
    }
  };

  return (
    <DetailsBox subtitle={node.moniker} onBackClick={handleOnBackClick}>
      {node && node.addr && (
        <div className={styles.nodeDetails}>
          <section className={styles.nodeDetailsLocation}>
            <span>
              <ReactCountryFlag
                countryCode={countryCode[node.location.country]}
                svg
                style={{
                  height: "1.5em",
                  width: "1.5em",
                }}
                title={node.location.country}
              />
            </span>
            <span>
              {node.location.city}, {node.location.country},{" "}
              {node.location.continent}
            </span>
            <Tooltip title={mapState.zoom > 2.5 ? "Zoom out" : "Zoom In"}>
              <Button
                icon={
                  mapState.zoom > 2.5 ? (
                    <MapZoomOutIcon />
                  ) : (
                    <AimOutlined
                      style={{ color: "#FFFFFF", fontSize: "1.2em" }}
                    />
                  )
                }
                ghost={true}
                className={"ghostButton"}
                style={{ cursor: "pointer", fontSize: "1.2em" }}
                onClick={handleResetMapViewport}
              />
            </Tooltip>
          </section>
          <section className={styles.nodeDetailsCards}>
            <NodeDetailsCard title={"Version"} value={node.version} />
            <NodeDetailsCard title={"Protocol Type"} value={types[node.type]} />
            <NodeDetailsCard
              title={"Download Speed"}
              value={`${formatBytes(node.internet_speed.download)}ps`}
            />
            <NodeDetailsCard
              title={"Upload Speed"}
              value={`${formatBytes(node.internet_speed.upload)}ps`}
            />
          </section>
          <NodeDetailsCard isCopy title={"Node Address"} value={node.addr} />
        </div>
      )}
    </DetailsBox>
  );
};

export default NodeDetails;
