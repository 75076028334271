import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
  initialState: {
    city: "",
    country: "",
    searchParamsLatitude: null,
    searchParamsLongitude: null,
  },
  name: "navigation",
  reducers: {
    setCurrentParams: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setCurrentParams } = navigationSlice.actions;

export default navigationSlice.reducer;
