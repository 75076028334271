import "mapbox-gl/dist/mapbox-gl.css";
import MapBoxGL from "mapbox-gl";
import React from "react";
import { mapConfig } from "../../constants/map.constants";
import { useSelector } from "react-redux";
import ReactMapGl, { Layer, NavigationControl, Source } from "react-map-gl";
import { createSearchParams, useNavigate } from "react-router-dom";

const mapSettings = {
  doubleClickZoom: true,
  dragPan: true,
  dragRotate: false,
  interactive: true,
  maxPitch: 0,
  maxZoom: 14,
  minPitch: 0,
  minZoom: 2.5,
  renderWorldCopies: false,
  touchPitch: false,
  touchZoomRotate: true,
};

const layerStyle = {
  id: "point",
  paint: {
    "circle-color": "#00B6FA",
    "circle-radius": 7,
  },
  type: "circle",
};

const Map = () => {
  const mapState = useSelector((state) => state.map.viewport);
  const data = useSelector((state) => state.map.locations);
  const navigation = useSelector((state) => state.navigation);
  const [cursor, setCursor] = React.useState("");
  const mapRef = React.useRef();
  const navigate = useNavigate();

  const handleFlyTo = (latitude, longitude, zoom) => {
    if (mapRef && mapRef?.current) {
      mapRef.current?.flyTo({
        center: [longitude, latitude],
        duration: 1000,
        essential: true,
        zoom: zoom,
      });
    }
  };

  React.useEffect(() => {
    handleFlyTo(mapState.latitude, mapState.longitude, mapState.zoom);
  }, [mapState.longitude, mapState.latitude, mapState.zoom[0]]);

  const onLoad = React.useCallback(() => {
    const map = mapRef.current.getMap();
    map.keyboard.disableRotation();
    map.touchZoomRotate.disableRotation();
    handleFlyTo(mapState.latitude, mapState.longitude, mapState.zoom);
  }, [mapState.latitude, mapState.longitude, mapState.zoom]);

  const onClick = React.useCallback(
    (event) => {
      const feature = event.features && event.features[0];
      if (feature && feature.properties) {
        const { latitude, longitude } = feature.properties;
        handleFlyTo(latitude, longitude, [14]);
        if (
          latitude.toString() === navigation.searchParamsLatitude &&
          longitude.toString() === navigation.searchParamsLongitude
        ) {
          return;
        }
        const params = { latitude, longitude };
        navigate({
          pathname: "/nodes",
          search: `?${createSearchParams(params)}`,
        });
      }
    },
    [navigation.searchParamsLatitude, navigation.searchParamsLongitude]
  );

  const geojson = {
    features: data.map(({ longitude, latitude, city, country }) => ({
      geometry: {
        coordinates: [longitude, latitude],
        type: "Point",
      },
      properties: {
        city,
        country,
        latitude,
        longitude,
      },
      type: "Feature",
    })),
    type: "FeatureCollection",
  };

  const layer = React.useMemo(() => (
    <Source id="my-data" type="geojson" data={geojson}>
      <Layer {...layerStyle} />
    </Source>
  ));

  return (
    <ReactMapGl
      mapLib={MapBoxGL}
      mapboxAccessToken={mapConfig.token}
      ref={mapRef}
      maxBounds={[
        [-180, -90],
        [180, 90],
      ]}
      onLoad={onLoad}
      onClick={onClick}
      onMouseEnter={() => setCursor("pointer")}
      onMouseLeave={() => setCursor("")}
      cursor={cursor}
      style={{ height: "100vh", width: "100vw" }}
      mapStyle={mapConfig.styles.custom}
      logoPosition="bottom-left"
      interactiveLayerIds={["point"]}
      initialViewState={{
        bearing: 0,
        latitude: mapState.latitude,
        longitude: mapState.longitude,
        pitch: 0,
        zoom: mapState.zoom,
      }}
      {...mapSettings}
    >
      {layer}
      <NavigationControl position={"bottom-right"} />
    </ReactMapGl>
  );
};

export default Map;
