import { configureStore } from "@reduxjs/toolkit";
import mapReducer from "../reducers/map.reducer";
import navigationReducer from "../reducers/navigation.reducer";
import { useSelector } from "react-redux";
import dataReducer, {
  bandwidthReducer,
  marketPriceReducer,
} from "../reducers/data.reducer";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 60 },
      serializableCheck: { warnAfter: 60 },
    }),
  reducer: {
    bandwidth: bandwidthReducer,
    map: mapReducer,
    marketPrice: marketPriceReducer,
    navigation: navigationReducer,
    nodes: dataReducer,
  },
});

export default store;

export const useFilteredData = () =>
  useSelector((state) => state.nodes.filteredData);
