/* eslint-disable no-unused-vars */
import ExplorerCard from "./container/ExplorerCard";
import Loader from "./Components/Loader/Loader";
import React from "react";
import routes from "./helpers/routes";
import styles from "./styles/app.module.scss";
import { Header, Map } from "./Components";
import { Route, Routes } from "react-router-dom";
import {
  getCurrentActiveNodes,
  getHistoricalBandwidthData,
  getMarketPrice,
} from "./actions/data.actions";
import { useDispatch, useSelector } from "react-redux";

const RoutesLayout = () => (
  <Routes>
    {routes.map(
      ({ route, component: Component }) =>
        Component && (
          <Route exact key={route} path={route} element={<Component />} />
        )
    )}
    <Route path="*" element={<ExplorerCard />} />
  </Routes>
);

const Navigation = RoutesLayout;

const App = () => {
  const dispatch = useDispatch();
  const { graph, total } = useSelector((state) => state.bandwidth);
  const loading = useSelector((state) => state.nodes.loading);

  React.useEffect(() => {
    dispatch(getMarketPrice());
    dispatch(getCurrentActiveNodes());
    if (total && graph && graph.length > 0) {
      return;
    } else {
      dispatch(getHistoricalBandwidthData());
    }
  }, []);
  return (
    <>
      <Header />
      {loading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <Map />
      )}
      <Navigation />
    </>
  );
};

export default App;
