import { createAsyncThunk } from "@reduxjs/toolkit";
import { getContinentName } from "../helpers/getCountsOfData";
import {
  fetchActiveNodes,
  fetchHistoricalBandwidthData,
  fetchMarketPrice,
  getCurrentActiveNodeCount,
} from "../api/data.services";

export const getMarketPrice = createAsyncThunk(
  "data/GET_MARKET_PRICE",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const data = await fetchMarketPrice();
      return fulfillWithValue({
        changedValue: Number.parseFloat(
          data.price_change_percentage_24h
        ).toFixed(2),
        isChangeNegative: Math.sign(data.price_change_percentage_24h) === -1,
        value: Number.parseFloat(data.current_price.usd).toFixed(6),
      });
    } catch (e) {
      return rejectWithValue();
    }
  }
);

export const getHistoricalBandwidthData = createAsyncThunk(
  "data/GET_BANDWIDTH_DATA",
  async (_, { fulfillWithValue, getState, rejectWithValue }) => {
    try {
      const { graph, total } = getState().bandwidth;
      if (total && graph && graph.length > 0) {
        return fulfillWithValue({ graph, total });
      }
      const response = await fetchHistoricalBandwidthData();
      let totalValue = 0;
      const graphData = response
        .reduce((prev, curr) => {
          const value = parseInt(
            parseInt(curr.value.download) + parseInt(curr.value.upload)
          );
          totalValue = totalValue + value;
          return [...prev, { name: curr.timestamp, value }];
        }, [])
        .reverse();
      return fulfillWithValue({
        graph: graphData,
        total: totalValue,
      });
    } catch (e) {
      return rejectWithValue();
    }
  }
);

const parseActiveNodes = (nodes) => {
  const filterData = nodes.reduce((prev, curr) => {
    if (curr && curr.location)
      return [
        ...prev,
        {
          addr: curr.addr,
          handshake_dns: curr.handshake_dns,
          internet_speed: curr.internet_speed
            ? curr.internet_speed
            : { download: 0, upload: 0 },
          location: {
            ...curr.location,
            city: curr.location ? curr.location.city || "Unknown" : "Unknown",
            continent: curr.location
              ? getContinentName(curr.location?.country)
              : "Unknown",
            country: curr.location
              ? curr.location.country || "Unknown"
              : "Unknown",
            latitude: curr.location ? curr.location.latitude || 0.0 : 0.0,
            longitude: curr.location ? curr.location.longitude || 0.0 : 0.0,
          },
          moniker: curr.moniker || "Unknown",
          type: curr.type || 0,
          version: curr.version || "Unknown",
        },
      ];
    return [...prev];
  }, []);
  const parsed = filterData.filter((el) => el);
  return parsed.sort((a, b) => a.moniker.localeCompare(b.moniker));
};

export const getCurrentActiveNodes = createAsyncThunk(
  "data/GET_ACTIVE_NODES",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      let length = await getCurrentActiveNodeCount();
      length = length.flat(1)[0].value;
      const response = await fetchActiveNodes(length);

      const parsed = parseActiveNodes(response);
      return fulfillWithValue({
        data: parsed,
        total: parsed.length,
      });
    } catch (e) {
      return rejectWithValue();
    }
  }
);
