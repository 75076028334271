import CitiesList from "../container/CitiesList";
import ContinentsList from "../container/ContinentsList";
import CountriesList from "../container/CountriesList";
import ExplorerCard from "../container/ExplorerCard";
import NodeDetails from "../container/NodeDetails";
import NodesList from "../container/NodesList";

const routes = [
  {
    component: ExplorerCard,
    route: "/",
  },
  {
    component: NodesList,
    route: "/nodes",
  },
  {
    component: NodeDetails,
    route: "/nodes/:addr",
  },
  {
    component: ContinentsList,
    route: "/continents",
  },
  {
    component: CountriesList,
    route: "/continents/:continent/countries",
  },
  {
    component: CountriesList,
    route: "/countries",
  },
  {
    component: CitiesList,
    route: "/countries/:country/cities",
  },
  {
    component: NodesList,
    route: "/countries/:country/cities/:city/nodes",
  },
  {
    component: CitiesList,
    route: "/cities",
  },
];

export default routes;
